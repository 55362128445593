import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { getCookieByName } from '../utils/cookies';

import LS_KEYS from '../constants/local-storage';

import Context from './Context';

class ContextProvider extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  state = {
    activeChapter: '',
    activeSection: '',
    chapterScrollPct: 0,
    isContentGated: true,
    isPreviousCustomer: false,
    isSubtopicPage: false,
    hasChapterCTA: true,
    hasFullscreenCTA: false,
    hasSubTopicCTA: false,
    scrollToChapter: '',
    scrollToSection: '',
    theme: 'light',
  }

  componentDidMount() {
    const userEmail = window.localStorage.getItem(LS_KEYS.WFG_KNOWN_USER);

    const hasFioCookie = getCookieByName('fio-has-logged-in') ||
      getCookieByName('blog_newsletter_subscribed');

    const hasBlogSubscribeCookie = getCookieByName('blog_wfg_subscribed');

    const isKnownUser = userEmail || hasBlogSubscribeCookie;

    this.setState({
      isContentGated: !isKnownUser,
      isPreviousCustomer: !!hasFioCookie,
    });
  }

  render() {
    const { children } = this.props;

    return (
      <Context.Provider value={ {
        state: {
          ...this.state,
        },
        actions: this.getActions(),
      } }
      >
        { children }
      </Context.Provider>
    );
  }

  handleChangeTheme = () => {
    const { theme } = this.state;

    const newTheme = theme === 'light' ? 'dark' : 'light';

    this.setState({
      theme: newTheme,
    });
  }

  handleChangeScrollToChapter = slug => {
    this.setState({
      scrollToChapter: slug,
    });
  }

  handleChangeScrollToSection = sectionID => {
    this.setState({
      scrollToSection: sectionID,
    });
  }

  handleChangeActiveChapter = slug => {
    const { scrollToChapter } = this.state;

    const urlParams = queryString.stringify({ ch: slug });
    window.history.pushState(null, null, `#${ urlParams }`);

    if (!scrollToChapter) {
      window.analytics.track('wfg-chapter-viewed', { chapter: slug });
    }

    this.setState({
      activeChapter: slug,
      chapterScrollPct: 0,
    });
  }

  handleChangeActiveSection = sectionID => {
    const { activeChapter } = this.state;

    this.setState({
      activeSection: sectionID,
    });

    const urlParams = queryString.stringify({ ch: activeChapter, s: sectionID });
    window.history.pushState(null, null, `#${ urlParams }`);
  }

  handleChangeActiveSectionToPrevious = sectionID => {
    const { activeChapter } = this.state;

    this.setState({
      activeSection: sectionID,
    });

    const urlParams = queryString.stringify({ ch: activeChapter, s: sectionID });
    window.history.pushState(null, null, `#${ urlParams }`);
  }

  handleChangeChapterScrollPct = pct => {
    this.setState({
      chapterScrollPct: pct,
    });
  }

  handleChangeContentGated = (isContentGated = false) => {
    this.setState({
      isContentGated,
    });
  }

  handleChangeIsSubtopicPage = isSubtopicPage => {
    this.setState({
      isSubtopicPage,
    });
  }

  handleChangeContextState = changedProperties => {
    this.setState({
      ...changedProperties,
    });
  }

  handleChangeHasFullscreenCTA = hasFullscreenCTA => {
    this.setState({
      hasFullscreenCTA,
    });
  }

  getActions = () => ({
    onChangeTheme: this.handleChangeTheme,
    onChangeActiveChapter: this.handleChangeActiveChapter,
    onChangeActiveSection: this.handleChangeActiveSection,
    onChangeActiveSectionToPrevious: this.handleChangeActiveSectionToPrevious,
    onChangeChapterScrollPct: this.handleChangeChapterScrollPct,
    onChangeContentGated: this.handleChangeContentGated,
    onChangeContextState: this.handleChangeContextState,
    onChangeScrollToChapter: this.handleChangeScrollToChapter,
    onChangeScrollToSection: this.handleChangeScrollToSection,
    onChangeIsSubtopicPage: this.handleChangeIsSubtopicPage,
    onChangeHasFullscreenCTA: this.handleChangeHasFullscreenCTA,
  })
}

export default ContextProvider;
