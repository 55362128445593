export const setCookie = (name, value, days, domain) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${ date.toUTCString() }`;
  }

  document.cookie = `${ name }=${ value || '' }${ expires }; path='/'; domain=${ domain || '' }`;
};

export const getCookieByName = name => {
  const value = `; ${ document.cookie }`;
  const parts = value.split(`; ${ name }=`);

  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }

  return undefined;
};
